import React from "react";
import { useTranslation } from "react-i18next";
import { translationHelper } from "helpers/translation.helper";
import Layout from "components/layout.component";
import { TranslatableStringInterface } from "interfaces/translatable-string.interface";

interface DeliveryTermsPageProps {
    pageContext: {
        terms_and_conditions: TranslatableStringInterface;
    };
}

const TermsAndConditionsPage: React.FC<DeliveryTermsPageProps> = (props) => {
    const { i18n } = useTranslation();

    const { terms_and_conditions } = props.pageContext;

    return (
        <Layout>
            <div className="prose bg-white overflow-hidden shadow sm:rounded-md bg-white w-full max-w-full">
                <div
                    className="px-4 py-4 sm:px-6"
                    dangerouslySetInnerHTML={{
                        __html: translationHelper(
                            terms_and_conditions,
                            i18n.language
                        ),
                    }}
                />
            </div>
        </Layout>
    );
};

export default TermsAndConditionsPage;
